import { APIFilter } from '@/utils/api'

export default {
  async selectSobre (Vue, filter, search, sorter, page, id, idtabla) {
    const apiFilter = new APIFilter()
      .addGT('estado', 0)
      .addExact('id', id)
      .addExact('idtabla', idtabla)
    if (search) {
      apiFilter.addNestedFilter(Vue.$online.sobre.searchFilter(search))
    }
    const resp = await Vue.$api.call(
      'sobre.select', {
        page,
        filter: apiFilter,
        sorter,
      },
    )
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async selectSobreRows (Vue, pks) {
    const apiFilter = new APIFilter()
    apiFilter.addIn('idsobre', pks)
    const resp = await Vue.$api.call('sobre.select', { filter: apiFilter })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
}
